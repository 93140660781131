import React from 'react';
import { Link } from 'gatsby';
import PrimaryButton from './atomic/PrimaryButton';
import styled from 'styled-components';

const Mobile = styled.div`
  background-color: #121F3F;
  color: #fff;
  padding: 2rem;
  position: fixed;
  top: 0;
  bottom: 0;
  left: ${props => props.menuOpen === true ? '20vw': '100vw'};
  width: 80vw;
  height: 100vh;
  transition: all 0.5s ease-in-out;
  z-index: 100;
  box-shadow: ${props => props.menuOpen === true ? '-10px 0 30px rgba(0,0,0,0.3)': 'none'};
  .close{
    width: 100%;
    text-align: right;
    font-family: 'Bebas Neue';
    font-size: 1.25em;
  }
  .links{
    display: flex;
    flex-direction: column;
    color: #fff;
    margin-top: 3rem;
    a{
      font-family: 'Bebas Neue';
      color: #fff;
      font-size: 1.5em;
      margin-bottom: 1em;
    }
  }
`;

const MobileHeader = ({menuOpen, toggleMenu}) => (
  <Mobile menuOpen={menuOpen}>
    <div className="close" onClick={toggleMenu}>Cerrar</div>
    <div className="links">
      <Link to="/">Inicio</Link>
      <Link to="/propuestaeducativa">Propuesta Educativa</Link>
      <Link to="/galeria">Galería</Link>
      <Link to="/inscripciones">Inscripciones</Link>
      <br/><br/>
      <PrimaryButton className="prim-button" text="Contacto" to="/contacto" />
    </div>
  </Mobile>
)

export default MobileHeader;