import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const Button = styled.div`
  background-color: #121F3F;
  display: flex;
  padding: 0.5rem 0.65rem;
  color: #fff;
  border-radius: 3px;
  img{
    margin-bottom: 0;
    height: 26px;
  }
`

const SecondaryButton = ({ children, text, icon, to }) => (
  <Link className="stretch" to={to}>
    <Button>
      {text || children}
    </Button>
  </Link>
);

export default SecondaryButton;