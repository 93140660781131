import React from 'react';
import styled from 'styled-components';

const Header = styled.div`
  width: 100%;
  background-image: url(${props => props.bg});
  background-position: 50% 50%;
  background-size: cover;
  height: 40vh;
`

const PageHeader = ({bg}) => (
  <Header bg={bg} />
)

export default PageHeader;