import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import iconFile from '../../images/icon.svg';

const Button = styled.div`
  background-color: #EC6909;
  display: flex;
  padding: 0.5rem 1rem;
  color: #fff;
  border-radius: 3px;
  font-size: 22px;
  font-family: 'Bebas Neue';
  letter-spacing: 1px;
  ${({ icon }) => icon && `
    justify-content: space-between;
    min-width: 240px;
  `}
  img{
    margin-bottom: 0;
  }
`

const PrimaryButton = ({ text, icon, to }) => (
  <Link to={to}>
    <Button icon={icon}>
      <span>{text}</span>
      {icon && <img src={iconFile} alt=""/>}
    </Button>
  </Link>
);

export default PrimaryButton;