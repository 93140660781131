import { Link } from 'gatsby';
import React from 'react';
import Headroom from 'react-headroom';
import styled from 'styled-components'; 

import PrimaryButton from './atomic/PrimaryButton';
import SecondaryButton from './atomic/SecondaryButton';

import logo from '../images/logo.png';
import phone from '../images/phone.svg';

const BurgerMenu = styled.div`
  color: #fff;
  display: none;
  align-items: center;
  justify-content: flex-end;
  font-family: 'Bebas Neue';
  font-size: 1.25em;
  a{
    margin-left: 1rem;
  }
  .label{
    margin-left: 1rem;
  }
  @media (max-width: 768px){
    display: flex;
    .label{
      color: #121F3F;
    }
  }
`

const DesktopMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  a{
    font-family: 'Bebas Neue';
    font-size: 1.25em;
    text-decoration: none;
    margin: 0 0.6rem;
    color: #121F3F;
  }
  @media (max-width: 768px){
    display: none;
  }
`

const Header = ({lightHeader, toggleMenu}) => (
  <Headroom className={`${lightHeader ? null : 'dark-header'}`}>
    <div className="banner">
      <div className="wrapper">
        Escríbenos <a href="mailto:contacto@ihb.edu.mx">contacto@ihb.edu.mx</a> y resolveremos cualquiera de tus dudas.
      </div>
    </div>
    <header className="wrapper">
      <Link to="/" className="logo">
        <img src={logo} alt="Instituto Hidalgo Bilingüe"/>
      </Link>
      <DesktopMenu className={`menu ${lightHeader ? 'light-header' : null}`}>
        <Link to="/propuestaeducativa">Propuesta Educativa</Link>
        <Link to="/galeria">Galería</Link>
        <Link to="/inscripciones">Inscripciones</Link>
        <a href="tel:5553795977" className="header-secondary">
          <img src={phone} alt="Llámanos"/>
        </a>
        <PrimaryButton text="Contacto" to="/contacto" />
      </DesktopMenu>
      <BurgerMenu>
        <SecondaryButton to="/"><img src={phone} alt="Llámanos"/></SecondaryButton>
        <PrimaryButton text="Contacto" to="/" />
        <div className={`label ${lightHeader ? 'white-label' : ''}`} onClick={toggleMenu}>Menú</div>
      </BurgerMenu>
    </header>
  </Headroom>
)

export default Header
