/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useEffect, useRef } from "react"
import { TweenMax } from "gsap"
import PropTypes from "prop-types"

import Header from "./header"
import MobileHeader from './MobileHeader';
import Footer from "./footer"
import "./layout.css"
import "../styles/main.scss"

const Layout = ({ children, lightHeader }) => {
  const [menuOpen, openMenu] = useState(false);

  let app = useRef(null);

  useEffect(() => {
    TweenMax.to(app, 0, {css: {visibility: 'visible'}});
  }, null)

  function handleToggleMenu() {
    if(menuOpen){
      openMenu(false);
    } else {
      openMenu(true)
    }
  }
  return (
    <div className="layout-container" ref={el => app = el}>
      <Header lightHeader={lightHeader} toggleMenu={handleToggleMenu} />
      <MobileHeader menuOpen={menuOpen} toggleMenu={handleToggleMenu} />
      <div>
        <main>{children}</main>
      </div>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
