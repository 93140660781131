import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import logo from '../images/logo.png';

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`

const Links = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #F3F3F3;
  align-items: center;
  padding: 1.4rem 0;
  .wrapper{
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    nav{
      font-family: 'Bebas Neue';
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      a{
        margin-left: 1rem;
        color: #8A8A8A;
      }
    }
    @media (max-width: 425px){
      flex-direction: column;
      nav{
        flex-direction: column;
        a{
          margin-bottom: 10px;
        }
      }
    }
  }
`

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #EC6909;
  img{
    width: 60px;
    margin: 0;
    margin-right: 1.5rem;
  }
  h3{
    margin-bottom: 0;
  }
  @media (max-width: 425px){
    justify-content: center;
    margin-bottom: 2rem;
  }
`

const Copyright = styled.div`
  background-color: #BCC1CD;
  font-size: 13px;
  padding: 5px 0;
  .wrapper{
    margin: 0 auto;
  }
`

const Footer = () => (
  <FooterContainer>
    <Links>
      <div className="wrapper">
        <LogoContainer>
          <img src={logo} alt="Instituto Hidalgo Bilingüe" />
          <h3>Instituto Hidalgo Bilingüe</h3>
        </LogoContainer>
        <nav>
          <Link to="/">Inicio</Link>
          <Link to="/galeria">Galería</Link>
          <Link to="/reconocimientos">Reconocimientos</Link>
          <Link to="/inscripciones">Inscripciones</Link>
          <Link to="/contacto">Contacto</Link>
        </nav>
      </div>
    </Links>
    <Copyright>
      <div className="wrapper">
       Derechos reservados © {new Date().getFullYear()}.
      </div>
    </Copyright>
  </FooterContainer>
);

export default Footer;